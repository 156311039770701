@import "../../shared.scss";

.container {
    padding: 0;

    .previewContainer {
        .empty {
            padding: 1em;
            font-style: italic;
            color: $grey;
        }

        h4, h5 {
            margin: .75em 0;
            color: $green-400;
        }

        p, pre {
            font-size: .75em;
        }
    }
}
