@keyframes fadeInImg {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.loading {
    opacity: 0;
}

.loaded {
    animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
    position: relative;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-delay: 0.1s;
}
