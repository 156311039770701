@import "./colors.scss";

@mixin background_stripes($color1, $color2, $angle: 45deg, $size1: 10px, $size2: 12px) {
    background: repeating-linear-gradient(
        $angle,
        $color1,
        $color1 $size1,
        $color2 $size1,
        $color2 $size2
    )
}

// HTML ELEMENTS

header {
    @include background_stripes($white, $pure-white);
    padding: calc(24px + 8px) 32px 24px;
    border-bottom: 8px solid $white;
    box-sizing: border-box;
    position: sticky;
    top: 64px;
    z-index: 5000;

    &.loadingBar {
        //position: relative;
    }
}

section {
    padding: 24px;
    box-sizing: border-box;
}

.section-header {
  margin-bottom: 8px;
  margin-top: 18px;
}

nav {
    position: fixed;
    z-index: 9999;
    height: 64px;
    width: 100%;
}

p,div.container {
    margin-bottom: 24px;
    font-weight: 300;
}

div.container {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}

li {
    font-weight: 300;
    margin-bottom: 8px;
}

div.status-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 16px;
    font-size: 14px;
    transform: translateY(100%);
    transition: transform 0.4s ease-in-out;
    z-index: 9999;

    .title {
        font-weight: 700;
        margin-right: 16px;
    }

    .message {
        flex-grow: 1;
    }

    i {
        cursor: pointer;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }

    &.shown {
        transform: translateY(0);
    }

    &.info {
        background: $grey-300;
        color: $grey-700;
    }

    &.error {
        background: $darker-red;
        color: $white;
    }

    &.warning {
        background: $orange-600;
        color: $white;
    }

    &.success {
        background: $green-600;
        color: $white;
    }

    &.critical {
        background: $darker-red;
        color: $white;
    }
}

// HEADERS

%h {
    font-family: Roboto, Helvetica, serif;
    font-weight: 300;
    margin: 0;

    .highlight {
        font-weight: 500;
    }

    &.underline {
        &:after {
            content: "";
            display: block;
            height: 2px;
            width: 28px;
            background: $green-200;
        }
    }
}

h1,h2,h3,h4,h5,h6 {
    @extend %h;
}

h1,.h1 {
    @extend %h;
    font-size: 36px;
}

h2,.h2 {
    @extend %h;
    font-size: 24px;
    color: $green-300;
    font-weight: 300;
}

h3,.h3 {
    @extend %h;
}

// BUTTONS & LINKS

a,.link {
    color: $green-400;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $green-600;
    }
}

button {
    border: 1px solid $green-200;
    background: $green-200;
    color: $white;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    height: 32px;

    .fas,.far {
        margin-right: 4px;
    }

    &:focus {
        border-color: $green-300;
        outline: none;
    }

    &:hover {
        //background: linear-gradient(100deg, $green-300, $green-200);
        border-color: $green-400;
        background: $green-300;
    }

    &:disabled {
        background: $grey-200;
        color: $grey;
        border-color: $grey-300;
        cursor: default;
    }

    &.link {
        background: none;
        border: none;
        padding: 0;
        color: $green-300;

        &:hover {
            color: $green;
            background: none;
        }
    }

    &.alt {
        background: $blue-200;
        border-color: $blue-200;

        &:focus {
            border-color: $blue-300;
        }

        &:hover {
            border-color: $blue-400;
            background-color: $blue-300;
        }
    }

    &.remove {
        background: $red-200;
        border-color: $red-200;

        &:focus {
            border-color: $red-300;
        }

        &:hover {
            border-color: $red-400;
            background-color: $red-300;
        }
    }

    &.cancel {
        background: $grey-100;
        border-color: $grey-200;
        color: $grey;

        &:focus {
            border-color: $grey-300;
        }

        &:hover {
            border-color: $grey-300;
            background-color: $grey-200;
        }
    }

    &.fab {
        border: 1px solid $white;
        background: none;
        color: $grey;
        padding: 8px 16px;

        &:hover {
            border: 1px solid $grey-300;
            background-color: $grey-100;
        }

        &:focus {

        }

        &:disabled {

        }

        .fas,.far {
            margin-right: 0;
        }
    }
}

// FORMS

form,.form{
    display: flex;

    button {
        margin-right: 8px;
    }

    label {
        font-size: 12px;
        color: $grey-700;
        font-weight: 500;
        display: inline-block;
        padding: 2px 0;
    }

    .form-group {
        margin-right: 8px;

        &.inline {
            display: flex;
            align-items: center;
            width: 100%;

            .field {
                flex-grow: 1;

                input {
                    width: -webkit-fill-available;
                }
            }

            label {
                margin-right: 12px;
            }
        }

        &.select {
            padding-right: 2px;

            .field {
                width: 100%;
                margin-right: 40px;

                &:hover {

                    &::before {
                        background: $grey-200;
                    }
                }

                &::before {

                    content: '';
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    width: 30px;
                    background: $grey-100;
                    pointer-events: none;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 5px;
                    height: 5px;
                    border: solid $grey-400;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg) translateY(-50%);
                    pointer-events: none;
                    top: 14px;
                    right: 14px;
                    z-index: 1;
                }
            }
        }

        .field {
            padding: 0;

            position: relative;
            display: block;

            .form-control {
                display: block;
            }

            .form-control-readonly {
                width: 100%;
                margin-right: 8px;
            }

            input,select,textarea {
                font-size: 12px;
                line-height: 12px;
                border: 1px solid $grey-300;
                border-radius: 0;
                padding: 8px;
                margin: 0;
                background-color: $pure-white;

                outline: 0;

                &:hover {
                    border-color: $grey-400;
                }

                &:focus {
                    border-color: $green-300;
                    box-shadow: none;
                    outline: none;
                }

                &:read-only {
                    background: $darker-white;
                }

                &.has-error {
                    border-color: $red;
                }
            }

            select {
                appearance: textfield;
                width: 100%;
                cursor: pointer;
                line-height: 15px;
            }

            .alert {

                &.alert-danger {
                    color: $red;
                }
            }
        }
    }
}

// PRELOADERS

.loader,
.loader:after {
    //position: absolute;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}
.loader {
    //margin: 60px auto;
    z-index: 0;
    top: calc(50% - 26px);
    left: calc(50% - 26px);
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 10px solid $green-100;
    border-right: 10px solid $green-100;
    border-bottom: 10px solid $green-100;
    border-left: 10px solid $green-500;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

//.loadingBar {
//    height: 4px;
//    width: 100%;
//    position: relative;
//    overflow: hidden;
//    background-color: #ddd;
//}
.loadingBar:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    bottom: -8px;
    width: 200px;
    height: 5px;
    background-color: $green-300;
    animation: loadingBar 2s linear infinite;
}

@keyframes loadingBar {
    from {left: -200px; width: 200px;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}

// SWITCH
.switch {
    display: flex;
    align-items: center;

    .title {
        font-size: 14px;

        &.left {
            margin-right: 8px;
        }

        &.right {
            margin-left: 8px;
        }
    }

    /* The switch - the box around the slider */
    label {
        position: relative;
        display: inline-block;
        width: 54px;
        height: 28px;

        /* Hide default HTML checkbox */
        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: $green-200;
            }

            &:focus + .slider {
                //border: 1px solid $green-300;
            }

            &:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;

            /* Rounded sliders */
            &.round {
                border-radius: 34px;
            }

            &.round:before {
                border-radius: 50%;
            }
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
}

.bordered {
    border: 1px solid grey;
}

// COREWIKI icon

.corewiki {
    &:before {
        content: 'core';
        font-weight: 500;
        color: $pure-black;
    }

    &:after {
        content: 'wiki';
        font-weight: 500;
        color: $deepOrange-500;
    }
}









