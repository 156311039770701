@import "../../../shared";

.tabs {
    display: flex;
    background: $grey-100;
    height: 52px;

    .tab {
        padding: 16px;
        cursor: pointer;

        &.active {
            background: $pure-white;

            &:hover {
                background: $pure-white;
                cursor: default;
            }
        }

        &.close {
            color: $grey;
            margin-left: auto;

            &:hover {
                background: $grey-100;
                color: $grey-700;
            }
        }

        &:hover {
            background: $grey-50;
        }
    }
}

.tabContent {
    padding: 16px;
    overflow-y: auto;
    height: -webkit-fill-available;
    box-sizing: border-box;
}