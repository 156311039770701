@import '../../shared.scss';

.wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.35);

    .content {
        position: absolute;
        left: 20%;
        right: 20%;
        top: 10%;
        bottom: 10%;
        padding: 18px;
        background: $pure-white;
        border: 5px solid $white;
    }

    .closeIcon {
        position: absolute;
        display: block;
        right: 18px;
        top: 12px;
        color: $grey-300;
        cursor: pointer;

        &:hover {
            color: $grey-400;
        }
    }

    .closeButton {
        position: absolute;
        bottom: 16px;
        right: 24px;
    }

    .header {
        font-weight: 400;
    }
}