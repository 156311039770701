ul.tokenlist { 
    list-style-type: none;
}

table {
    table-layout: fixed;
    width: 100%;
}

.breakwords {
    word-break: break-all;
}

.textaligncenter {
    text-align: center;
}